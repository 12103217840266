<template>
  <div class="all-user-container">
    <div class="form-action">
      <!-- filter desktop -->
      <div class="filter-desktop user-management">
        <!-- filter 1 -->
        <b-row class="input-control">
          <b-col md="9">
            <b-row class="input-control">
              <b-col
                cols="12"
                md="9"
                lg="7"
                class="input-height"
              >
                <div>
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-form-select
                          v-model="searchSelected"
                          class="select-search-custom"
                          :options="searchOptions"
                          @input="handleChangeSearchType"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model.trim="searchTerm"
                        :placeholder="
                          searchSelected === 'email'
                            ? $t('partnerView.placeholderSearchByEmail')
                            : $t('partnerView.placeholderSearchById')
                        "
                        :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                        class="d-inline-block input-height"
                        @input="handleSearchActivatedTab"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="9"
                lg="5"
                class="px-lg-0 mt-1 mt-lg-0 input-height"
              >
                <calendar
                  v-model="rangeDate"
                  @input="onSelectActivatedTab"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="3"
            class="mt-1 mt-md-0"
          >
            <btn-loading
              class="btn-export btn-partner"
              type="submit"
              block
              variant-convert="btn-submit"
              :loading="loadingDown"
              @click="submitExportData(statusSelected)"
            >
              {{ $t('partnerView.btnExportData') }}
              <feather-icon
                icon="UploadIcon"
                size="16"
              />
            </btn-loading>
          </b-col>
        </b-row>
      </div>
      <!-- /filter desktop -->
    </div>

    <!-- table -->
    <vue-good-table
      class="vgt-no-border"
      mode="remote"
      :columns="columns"
      :rows="listMember"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalMember > 0,
      }"
      :sort-options="{
        enabled: totalMember > 0,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ad Account -->
        <div
          v-if="props.column.field === 'fullName'"
          class="d-flex align-items-center cursor-pointer"
          @click.prevent="goToMemberDetail(props.row.localUserId)"
        >
          <b-img
            rounded
            class="ads-avt-img"
            :src="require('@/assets/images/common/ic-member-manager.svg')"
          />
          <div class="d-flex flex-column">
            <p class="text-primary font-weight-600 mb-0">
              {{ props.row.fullName ? props.row.fullName : null }}
            </p>
            <span class="text-placeholder">{{ props.row.email ? props.row.email : null }}</span>
          </div>
        </div>

        <!-- Column: phone -->
        <div
          v-else-if="props.column.field === 'contactInfo.phone'"
        >
          <span
            class="text-nowrap text-capitalize"
          >
            {{ showPhoneNumber(props.row) }}
          </span>
        </div>

        <!-- Column: contactInfo -->
        <div
          v-else-if="props.column.field === 'contactInfo'"
          class="d-flex flex-column"
        >
          <div v-if="props.row.contactInfo">
            <p
              v-if="facebookContact(props.row)"
              class="text-nowrap contact-facebook cursor-pointer m-0"
            >
              Facebook: <span
                class="text-link link-facebook"
                @click="goToUrl(facebookContact(props.row))"
              >{{ facebookContact(props.row) }}</span>
            </p>
            <p
              v-if="whatsAppContact(props.row)"
              class="text-nowrap contact-facebook cursor-pointer m-0"
            >
              {{ `WhatsApp: ${whatsAppContact(props.row)}` }}
            </p>
            <p
              v-if="telegramContact(props.row)"
              class="text-nowrap contact-facebook cursor-pointer m-0"
            >
              {{ `Telegram: ${telegramContact(props.row)}` }}
            </p>
          </div>
          <div v-else>
            <span>N/A</span>
          </div>
        </div>

        <!-- Column: monthlySpentTracking -->
        <div
          v-else-if="props.column.field === 'monthlySpentTracking'"
          class="d-flex align-items-center"
        >
          <span
            v-if="props.row.monthlySpentTracking"
            class="text-nowrap text-capitalize"
          >
            {{ totalMonthlySpentTracking(props.row.monthlySpentTracking) }}
          </span>
        </div>

        <!-- Column: status -->
        <div
          v-else-if="props.column.field === 'status'"
          class="d-flex align-items-center"
        >
          <span
            v-if="props.row.status"
            class="text-nowrap text-capitalize status-member"
            :class="styleStatus(props.row.status)"
          >
            {{ multiStatusTxt(props.row.status) }}
          </span>
        </div>

        <!-- contactInfo.verification.status -->
        <div
          v-else-if="props.column.field === 'contactInfo.verification.status'"
          class="position-relative"
        >
          <feather-icon
            v-if="statusUserInfo(props.row) === 'rejected' && rejectReason(props.row)"
            icon="EyeIcon"
            size="16"
            class="text-body align-middle cursor-pointer icon-reason-block"
            @click="openReasonReject(props.row)"
          />
          <span
            v-if="statusUserInfo(props.row)"
            class="text-status"
            :class="statusVariant(props.row)"
          >
            {{ statusUserInfo(props.row) }}
          </span>
          <span v-else> N/A </span>
        </div>

        <!-- Column: plans.subscription.status -->
        <div
          v-else-if="props.column.field === 'plans.subscription.status'"
          class="d-flex align-items-center"
        >
          <span
            v-if="statusSubscription(props.row.plans)"
            class="text-nowrap text-capitalize status-member"
            :class="stylePlanStatus(props.row.plans)"
          >
            {{ statusSubscription(props.row.plans) }}
          </span>
        </div>

        <!-- Column: belongToPartner.status -->
        <div
          v-else-if="props.column.field === 'plans.type'"
          class="d-flex align-items-center"
        >
          <span
            class="text-nowrap text-capitalize"
          >
            {{ props.row.plans && props.row.plans.type ? props.row.plans.type : "N/A" }}
          </span>
        </div>

        <!-- Column: totalSpending -->
        <div
          v-else-if="props.column.field === 'totalSpending'"
        >
          <span v-if="noDataTotalSpending(props.row.totalSpending)">
            N/A
          </span>
          <span v-else>
            {{ amountFormat(props.row.totalSpending) }}
          </span>
        </div>

        <!-- Column: requestedAt -->
        <span v-else-if="props.column.field === 'createdAt'">
          <div v-if="props.row.createdAt">
            {{ createdDate(props.row.createdAt) }}
          </div>
        </span>

        <!-- Column: Actions -->
        <!-- <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click.prevent="openRemoveMemberModal(props.row)"
              >
                <span> Remove </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span> -->

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalMember > 0"
        slot="pagination-bottom"
      >
        <div class="d-flex flex-wrap footer-tab">
          <b-col
            md="6"
            class="px-0 d-flex align-items-center"
          >
            <span
              class="text-secondary font-14"
            >{{ totalMember }} {{ $t('partnerView.textMember') }}</span>
          </b-col>
          <!-- page length -->
          <b-col
            v-if="totalMember > pageLength"
            md="6"
            class="px-0"
          >
            <b-pagination
              :total-rows="totalMember"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <div
        slot="emptystate"
        class="text-center"
      >
        <no-data-table-image class="my-5" />
      </div>
    </vue-good-table>

    <remove-member-modal
      ref="modal-remove-member-modal"
      :member-info="memberInfo"
    />

    <reason-reject-info
      ref="open-modal-reason-reject"
      :member-data="memberSelected || null"
    />
  </div>
</template>
<script>
import {
  BSpinner,
  BImg,
  BPagination,
  // BDropdownItem,
  // BDropdown,
} from 'bootstrap-vue'
import tableReferralProgramMixin from '@/mixins/tableReferralProgramMixin.vue'
import { VueGoodTable } from 'vue-good-table'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import Calendar from '@/components/Calendar.vue'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'
import RemoveMemberModal from './RemoveMemberModal.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('referralProgram')

export default {
  name: 'AllUserTab',
  components: {
    // bootstrap
    BSpinner,
    BImg,
    BPagination,
    // BDropdownItem,
    // BDropdown,

    VueGoodTable,

    NoDataTableImage,
    RemoveMemberModal,
    Calendar,
  },
  mixins: [tableReferralProgramMixin, toastification, envMixin],

  data() {
    return {
      memberInfo: {},
      statusSelected: 'active',
    }
  },

  computed: {
    ...mapGetters(['listMemberPartnerActivated', 'message', 'status']),

    listMember() {
      return _get(this.listMemberPartnerActivated, ['content'], [])
    },

    totalMember() {
      return _get(this.listMemberPartnerActivated, ['paging', 'total'], [])
    },
  },

  methods: {
    ...mapActions(['getListMemberPartnerActivated']),

    goToMemberDetail(params) {
      const routeData = this.$router.resolve(`/member/${params}`)
      window.open(routeData.href, '_blank')
    },

    openRemoveMemberModal(val) {
      this.memberInfo = val
      this.$refs['modal-remove-member-modal'].showModal()
    },

    async handleSearchActivatedTab(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.clearSearch()
      } else if (searching.length > 2) {
        this.currentPage = 1
        this.debounce = setTimeout(() => {
          this.fetchListMemberPartnerActivated()
        }, 600)
      }
    },

    onSelectActivatedTab(val) {
      if (val) {
        this.fetchListMemberPartnerActivated()
      }
    },

    clearSearch() {
      this.searchTerm = ''
      this.currentPage = 1
      this.fetchListMemberPartnerActivated()
    },

    async fetchListMemberPartnerActivated() {
      if (
        this.searchTerm
          && !this.validateEmail(this.searchTerm)
          && this.searchSelected === 'email'
      ) {
        return
      }
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...({ status: 'active' }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...this.rangeDates,

      }
      await this.getListMemberPartnerActivated(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
<style lang="scss">
.user-management {
  @media(max-width: 400px) {
    .custom-select {
      padding: 0.438rem 2rem 0.438rem 0.5rem;
    }

    .form-control {
      padding: 0.438rem;
    }
  }
}
</style>
<style lang="scss" scoped>
.input-height {
  height: 40px;
}

.all-user-container {
  .form-action {
    padding: 21px 24px;
  }
  .form-group {
    margin-bottom: 0;
  }
}
.footer-tab {
  padding: 27px 24px;
}

.status-member {
  padding: 6px 15.5px;
  border-radius: 100px;
}

.active {
  background: #1db9c31a;
  color: #1db9c3;
}

.blocked, .canceled {
  background: #e11c4e1a;
  color: #e11c4e;
}

.incomplete{
  background: #7366f01a;
  color: #7366f0
}

.trialing{
  background: #29c76f1a;
  color: #29c76f;
}

.past_due{
  background: #ff9e431a;
  color: #ff9e43;
}

.contact-facebook{
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-link{
  color: #3271ff;

  &:hover{
    text-decoration: underline;
  }
}

.btn-partner {
  &.btn-export {
    height: 40px;
    border-radius: 0.625rem;
  }
}

.icon-reason-block {
  position: absolute;
  right: 30px;
  top: -15px;
}

</style>
