<script>
/* eslint-disable global-require */
import {
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  // BButton,
  BRow,
  BCol,
  BInputGroupAppend,
  BFormGroup,
  BFormSelect,
  VBModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { USER_STATUS, PLAN_STATUS, STATUS_USER_INFO } from '@/constants'
import { SUBSCRIPTION_TYPE } from '@/constants/billing'
import { createNamespacedHelpers } from 'vuex'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import axios from '@axios'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import Ripple from 'vue-ripple-directive'
import ReasonRejectInfo from '@/views/partner-management/member-management/components/ReasonRejectInfo.vue'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('referralProgram')

export default {
  components: {
    flatPickr,
    vSelect,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    // BButton,
    BRow,
    BCol,
    BInputGroupAppend,
    BFormGroup,
    BFormSelect,
    BtnLoading,
    ReasonRejectInfo,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification],
  data() {
    return {
      memberSelected: null,

      loadingDown: false,

      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // date picker
      rangeDate: {
        startDate: null,
        endDate: null,
      },

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      // status filter
      statusSelected: {
        name: 'All status',
        value: '',
      },

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: this.$t('partnerView.labelUserId'),
          value: 'localUserId',
        },
        {
          text: this.$t('partnerView.labelUserEmail'),
          value: 'email',
        },
      ],

      planSelected: null,
      planOptions: [
        {
          text: 'All plan',
          value: null,
        },
        {
          text: 'Regular 1',
          value: [SUBSCRIPTION_TYPE.regular_1],
        },
        {
          text: 'Regular 2',
          value: SUBSCRIPTION_TYPE.regular_2,
        },
        {
          text: 'Regular 3',
          value: SUBSCRIPTION_TYPE.regular_3,
        },
        {
          text: 'Regular 4',
          value: SUBSCRIPTION_TYPE.regular_4,
        },
        {
          text: 'Regular 5',
          value: SUBSCRIPTION_TYPE.regular_5,
        },
        {
          text: 'Black Agency',
          value: SUBSCRIPTION_TYPE.black_agency,
        },
        {
          text: 'White Agency',
          value: SUBSCRIPTION_TYPE.white_agency,
        },
        {
          text: 'Enterprise',
          value: SUBSCRIPTION_TYPE.enterprise,
        },
      ],

      columns: [
        {
          label: this.$t('partnerView.labelUserId'),
          field: 'localUserId',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textUserInfo'),
          field: 'fullName',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textTotalSpending'),
          field: 'totalSpending',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
          width: '200px',
        },
        {
          label: this.$t('partnerView.textUserStatus'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf width-th',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textVerifyStatus'),
          field: 'contactInfo.verification.status',
          thClass: 'p-1 border-0 header-table-mf width-th',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textPlanStatus'),
          field: 'plans.subscription.status',
          thClass: 'p-1 border-0 header-table-mf width-th',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelPhoneNumber'),
          field: 'contactInfo.phone',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textContact'),
          field: 'contactInfo',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },

        {
          label: this.$t('partnerView.textAdAccounts'),
          field: 'adsAccounts.length',
          thClass: 'p-1 border-0 header-table-mf width-th',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textCurrentPlan'),
          field: 'plans.type',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textCreateAt'),
          field: 'createdAt',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        // {
        //   label: 'Action',
        //   field: 'action',
        //   thClass: 'p-1 border-0 header-table-mf',
        //   tdClass:
        //     'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        // },
      ],
    }
  },

  computed: {
    ...mapGetters(['listMemberPartner', 'message', 'status', 'loading']),

    noDataTotalSpending() {
      return data => data === null || data === undefined
    },

    totalMonthlySpentTracking() {
      return val => Object.values(val)
        .reduce((a, b) => a + b) || 0
    },

    adsAccountImg() {
      return img => img || require('@/assets/images/common/ic-member-manager.svg')
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '') || 'N/A'
    },

    styleStatus() {
      const status = {
        [USER_STATUS.ACTIVE]: 'active',
        [USER_STATUS.BLOCKED]: 'blocked',
      }

      return val => status[val]
    },

    multiStatusTxt() {
      const status = {
        [USER_STATUS.ACTIVE]: this.$t('stepGuideControl.textActive'),
        [USER_STATUS.BLOCKED]: this.$t('partnerView.tabBlocked'),
      }

      return val => status[val]
    },

    stylePlanStatus() {
      const status = {
        [PLAN_STATUS.INCOMPLETE]: 'incomplete',
        [PLAN_STATUS.TRIALING]: 'trialing',
        [PLAN_STATUS.ACTIVE]: 'active',
        [PLAN_STATUS.PAST_DUE]: 'past_due',
        [PLAN_STATUS.CANCELED]: 'canceled',
      }

      return val => status[val?.subscription?.status]
    },

    rangeDates() {
      return {
        from: this.rangeDate.startDate,
        to: this.rangeDate.endDate
          ? this.rangeDate.endDate
          : this.rangeDate.startDate,
      }
    },

    showPhoneNumber() {
      return val => {
        let result = ''
        const { contactInfo } = val
        if (contactInfo?.phone) {
          const phoneElm = contactInfo.phone.split('|')
          if (phoneElm.length === 3) {
            const [countryAbbr, countryCode, phone] = contactInfo.phone.split('|')
            result = `${countryAbbr.toUpperCase()} +${countryCode}${phone}`
          }
        }
        return result || 'N/A'
      }
    },

    facebookContact() {
      return val => val?.contactInfo?.facebook
    },

    whatsAppContact() {
      return val => val?.contactInfo?.whatsApp
    },

    telegramContact() {
      return val => val?.contactInfo?.telegram
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },

    statusSubscription() {
      const statusSub = {
        [PLAN_STATUS.INCOMPLETE]: this.$t('partnerView.statusIncomplete'),
        [PLAN_STATUS.TRIALING]: this.$t('partnerView.statusTrialing'),
        [PLAN_STATUS.ACTIVE]: this.$t('stepGuideControl.textActive'),
        [PLAN_STATUS.PAST_DUE]: this.$t('partnerView.statusPastDue'),
        [PLAN_STATUS.CANCELED]: this.$t('partnerView.statusCanceled'),
      }
      return val => statusSub[val?.subscription?.status] || 'N/A'
    },

    plansType() {
      return val => val?.type || 'N/A'
    },

    noFilter() {
      return this.searchTerm === '' && this.rangeDate.startDate === null && this.rangeDate.endDate === null
    },

    statusUserInfo() {
      const statusInfo = {
        [STATUS_USER_INFO.NOT_FOUND]: this.$t('productLinkPage.statusNotFound'),
        [STATUS_USER_INFO.REVIEWING]: this.$t('productLinkPage.statusReviewing'),
        [STATUS_USER_INFO.REJECTED]: this.$t('billingPage.statusRejected'),
        [STATUS_USER_INFO.APPROVED]: this.$t('myAdAccountsPage.textApproved'),
      }

      return info => statusInfo[info?.contactInfo?.verification?.status]
    },

    statusVariant() {
      const statusColor = {
        [STATUS_USER_INFO.NOT_FOUND]: 'not-found',
        [STATUS_USER_INFO.REJECTED]: 'rejected',
        [STATUS_USER_INFO.REVIEWING]: 'reviewing',
        [STATUS_USER_INFO.APPROVED]: 'approved',
      }

      return info => statusColor[info?.contactInfo?.verification?.status]
    },

    rejectReason() {
      return info => info?.contactInfo?.verification?.rejectedReason
    },
  },

  methods: {
    ...mapActions(['getListMemberPartner']),

    openReasonReject(data) {
      this.memberSelected = data
      this.$refs['open-modal-reason-reject'].showModal()
    },

    async fetchListMemberPartner() {
      if (
        this.searchTerm
        && !this.validateEmail(this.searchTerm)
        && this.searchSelected === 'email'
      ) {
        return
      }
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        // ...(this.planSelected && { planType: this.planSelected }),
        ...(this.statusSelected && { status: this.statusSelected }),
        ...this.rangeDates,
      }
      await this.getListMemberPartner(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    onSelect(val) {
      if (val) {
        this.fetchListMemberPartner()
      }
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.clearSearch()
      } else if (searching.length > 2) {
        this.currentPage = 1
        this.debounce = setTimeout(() => {
          this.fetchListMemberPartner()
        }, 600)
      }
    },

    async handlePlanChange() {
      this.currentPage = 1
      await this.fetchListMemberPartner()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListMemberPartner()
    },

    clearSearch() {
      this.searchTerm = ''
      this.currentPage = 1
      this.fetchListMemberPartner()
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchListMemberPartner()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListMemberPartner()
    },

    async exportExcel(val) {
      this.loadingDown = true
      try {
        const params = {
          ...(this.sortTerm.field && {
            sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
              this.sortTerm.field
            }`,
          }),
          ...(val && { status: val }),
          ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
          ...this.rangeDates,
        }

        const { data } = await axios.get('/api/partnership/members/export', {
          responseType: 'blob',
          params,
        })
        this.loadingDown = false
        const url = URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `list-member-${new Date().toLocaleDateString()}.xlsx`,
        )
        document.body.appendChild(link)
        link.click()
        this.loadingDown = false
      } catch (e) {
        if (e) {
          const error = await new Response(e.data).json()
          this.toastFailure(error.message)
        } else {
          this.toastFailure('Server Error')
        }
        this.loadingDown = false
      }
    },

    submitExportData(data) {
      this.loadingDown = true
      if (this.noFilter) {
        this.$bvModal.msgBoxConfirm(this.$t('partnerView.contentConfirmExportData'), {
          title: this.$t('payment.creditCard.titleModalConfirmRemoveCard'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('partnerView.btnExportData'),
          cancelTitle: this.$t('common.btnClose'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
          .then(value => {
            if (value) {
              this.exportExcel(data)
            } else {
              this.loadingDown = false
            }
          })
      } else {
        this.exportExcel(data)
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.width-th {
  min-width: 150px !important;
  width: auto;
}

.th-reason-rejected {
  min-width: 450px !important;
}

.reason-reject-content {
  p {
    margin: 0;
  }
}

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 40px;
}

.form-action {
  .currency {
    .vs__dropdown-toggle {
      height: 100%;
      border-radius: 12px;
    }
  }

  .product-tag {
    .b-form-tags {
      padding: 0;
      border: none;
    }

    .card {
      border-radius: 0.625rem;
    }

    .card-body {
      padding: 0.86rem 1rem;
    }
  }

  .country-tag {
    .b-form-tags {
      padding: 0;
      border: none;
    }

    .vs__selected {
      background: rgba(22, 33, 62, 0.05);
      color: rgba(22, 33, 62, 0.7);
    }

    .vs__dropdown-option--highlight {
      background: rgba(22, 33, 62, 0.05);
      color: rgba(22, 33, 62, 0.7);
    }
  }

  .input-height {
    .vs__dropdown-toggle {
      border: 1px solid #e0e0e5;
      height: 40px;
    }
  }
}

.text-status {
  text-transform: capitalize;
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  &.not-found {
    color: #babfc7;
    background-color: #babfc71a;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}
</style>
<style lang="scss" scoped>
</style>
